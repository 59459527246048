import email from '../assets/email.png';
import gmail from '../assets/gmail.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import flutter from '../assets/flutter.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import circle from '../assets/circle.svg';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';

import jclogosize from '../assets/jclogosize.png'
import jclogosmall from '../assets/jclogosmall.png'
import jclogo from '../assets/jclogo.png'
import google from '../assets/google.png'
import kolsaspfpwhite from '../assets/kolsaspfpwhite.png'
import kolsaspfp from '../assets/kolsaspfp.png'

import blob from '../assets/blob.svg'
import linkedin from '../assets/linkedin.svg'
import bgblue from '../assets/bgblue.png'
import bgblue2 from '../assets/bgblue2.png'
import ucdavisblue from '../assets/ucdavisblue.svg'
import bloblb from '../assets/bloblb.svg'

export default {
  email,
  gmail,
  api,
  cpp,
  flutter,
  about01,
  about02,
  about03,
  about04,
  circle,
  adidas,
  amazon,
  asus,
  jclogosize,
  jclogosmall,
  blob,
  linkedin,
  bgblue,
  bgblue2,
  google,
  ucdavisblue,
  jclogo,
  bloblb,
  kolsaspfpwhite,
  kolsaspfp,
};